import * as noUiSlider from "nouislider";
import "nouislider/dist/nouislider.css";
import "./range-slider.css";

const format = {
	from: (s) => Number(s),
	to: (i) => Math.round(i),
};

/**
 *
 * @param {HTMLDivElement} slider
 * @param {HTMLInputElement} inputStart
 * @param {HTMLInputElement} inputEnd
 */
export function setupIntegerRangeSlider(slider, inputStart, inputEnd) {
	let newSlider = document.createElement("div");
	newSlider.classList.add("slider-styled", "slider-round");
	slider.replaceWith(newSlider);

	let start = [inputStart.value, inputEnd.value];
	let range = { min: parseInt(inputStart.min), max: parseInt(inputStart.max) };

	noUiSlider.create(newSlider, {
		start,
		step: 1,
		connect: true,
		tooltips: [format, format],
		range,
		format,
	});

	newSlider.noUiSlider.on("update", (values, handle) => {
		let value = values[handle];
		if (handle) {
			inputEnd.value = value;
		} else {
			inputStart.value = value;
		}
	});

	inputStart.addEventListener("change", function () {
		newSlider.noUiSlider.set([this.value, null]);
	});

	inputEnd.addEventListener("change", function () {
		newSlider.noUiSlider.set([null, this.value]);
	});
}

/**
 *
 * @param {HTMLDivElement} slider
 * @param {HTMLInputElement} inputStart
 * @param {HTMLInputElement} inputEnd
 */
export function setupIntegerMaxSlider(slider, inputStart, inputEnd) {
	let newSlider = document.createElement("div");
	newSlider.classList.add("slider-styled", "slider-round");
	slider.replaceWith(newSlider);

	let range = { min: parseInt(inputStart.min), max: parseInt(inputStart.max) };

	noUiSlider.create(newSlider, {
		start: inputEnd.value,
		step: 1,
		connect: [true, false],
		tooltips: [format],
		range,
		format,
	});

	newSlider.noUiSlider.on("update", (values, handle) => {
		let value = values[handle];
		inputEnd.value = value;
	});

	inputEnd.addEventListener("change", function () {
		newSlider.noUiSlider.set([this.value]);
	});
}

/**
 *
 * @param {HTMLDivElement} slider
 * @param {HTMLInputElement} inputStart
 * @param {HTMLInputElement} inputEnd
 */
export function setupIntegerMinSlider(slider, inputStart, inputEnd) {
	let newSlider = document.createElement("div");
	newSlider.classList.add("slider-styled", "slider-round");
	slider.replaceWith(newSlider);

	let range = { min: parseInt(inputStart.min), max: parseInt(inputStart.max) };

	noUiSlider.create(newSlider, {
		start: inputStart.value,
		step: 1,
		connect: [false, true],
		tooltips: [format],
		range,
		format,
	});

	newSlider.noUiSlider.on("update", (values, handle) => {
		let value = values[handle];
		inputStart.value = value;
	});

	inputStart.addEventListener("change", function () {
		newSlider.noUiSlider.set([this.value]);
	});
}

/**
 *
 * @param {HTMLDivElement} slider
 * @param {HTMLInputElement} inputStart
 * @param {HTMLInputElement} inputEnd
 */
export function setupIntegerExactSlider(slider, inputStart, inputEnd) {
	let newSlider = document.createElement("div");
	newSlider.classList.add("slider-styled", "slider-round");
	slider.replaceWith(newSlider);

	let range = { min: parseInt(inputStart.min), max: parseInt(inputStart.max) };

	noUiSlider.create(newSlider, {
		start: inputStart.value,
		step: 1,
		connect: [false, false],
		tooltips: [format],
		range,
		format: format,
	});

	newSlider.noUiSlider.on("update", (values, handle) => {
		let value = values[handle];
		inputStart.value = value;
		inputEnd.value = value;
	});

	inputStart.addEventListener("change", function () {
		newSlider.noUiSlider.set([this.value]);
		inputEnd.value = this.value;
	});
}
